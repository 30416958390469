import { listOrders_orders_edges_node, listOrders_orders_edges_node_guest } from './graphql/listOrders';

import { listOffers_offers_edges_node_OfferTemplate , Pos } from './graphql/listOffers';

import { OfferTemplateWithdrawalType } from "./graphql/globalTypes";

export type AppOrderState = OrderState | LocalOrderState;

export enum LocalOrderState {
    LATE = 'LATE',
    URGENT = 'URGENT',
}

// States managed by the application, excludes "CART" and "ON_HOLD"
export enum OrderState {
    ACCEPTED = 'ACCEPTED',
    PREPARING = 'PREPARING',
    READY = 'READY',
    COMPLETED = 'COMPLETED',
    REFUSED = 'REFUSED',
    CANCELLED = 'CANCELLED',
    ABANDONED = 'ABANDONED'
}

// @ts-ignore
export interface IOrder extends listOrders_orders_edges_node {
    withdrawRange: [Date, Date];
    isUrgent?: boolean;
    isLate?: boolean;
    tableNumber: number | null;
    pickupPoint: IPickupPoint | null;
    offer: IOffer;
    comment: string | null;
    guest: listOrders_orders_edges_node_guest;
}

export interface IOfferTemplate {
    id: string;
    value: string;
    name: string;
    label: listOffers_offers_edges_node_OfferTemplate['name'];
    secondaryLabel: string | undefined;
    withdrawalType: OfferTemplateWithdrawalType | null;
    daysInAdvanceEnd: number | null;
    offers: any[];
    pos: Pos;
}

export interface IOffer {
    id: string;
    value: string;
    label: listOffers_offers_edges_node_OfferTemplate['name'];
    secondaryLabel: string | undefined;
    offerTemplateId: string;
    withdrawalType: OfferTemplateWithdrawalType | null;
    isInAdvance: boolean;
    withdrawRange: string;
    offerTemplateOffers?: any;
    count?: number;
    offerTemplate: IOfferTemplate
}

export interface IOfferWithOrder {
    id: string;
    value: string;
    label: string;
    secondaryLabel: string | undefined;
    offerTemplateId: string;
    withdrawalType: OfferTemplateWithdrawalType | null;
    isInAdvance: boolean;
    withdrawRange: string;
    count?: number;
}

export interface IPickupPoint {
    id: string;
    name: string
}
