/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum ArticleBaking {
  MEDIUM = "MEDIUM",
  MEDIUMRARE = "MEDIUMRARE",
  RARE = "RARE",
  RAW = "RAW",
  ROSE = "ROSE",
  WELLDONE = "WELLDONE",
}

export enum ArticleFamily {
  BEVERAGE = "BEVERAGE",
  BREAD = "BREAD",
  BREAKFAST = "BREAKFAST",
  CHEESE = "CHEESE",
  DAIRY = "DAIRY",
  DESSERT = "DESSERT",
  DISH = "DISH",
  FORMULA = "FORMULA",
  FRUIT = "FRUIT",
  INGREDIENTS = "INGREDIENTS",
  MISCELLANEOUS = "MISCELLANEOUS",
  OTHERS = "OTHERS",
  PASTRY = "PASTRY",
  SALAD = "SALAD",
  SANDWICH = "SANDWICH",
  SIDE_DISH = "SIDE_DISH",
  SNACKING = "SNACKING",
  SOUP = "SOUP",
  STARTER = "STARTER",
}

export enum Currency {
  EUR = "EUR",
}

export enum OfferTemplateWithdrawalType {
  CLICK_AND_PICK_UP = "CLICK_AND_PICK_UP",
  CONNECTED_LOCKERS = "CONNECTED_LOCKERS",
  INSTANT_CLICK_COLLECT = "INSTANT_CLICK_COLLECT",
  POS_AT_SITE = "POS_AT_SITE",
  POS_CLICK_SERVE = "POS_CLICK_SERVE",
  POS_TAKE_AWAY = "POS_TAKE_AWAY",
  TABLE_SERVICE = "TABLE_SERVICE",
}

export enum OfferPaymentMethod {
  Badge = "BADGE",
  CreditCard = "CREDIT_CARD",
  Edenred = "EDENRED",
  OnSite = "ON_SITE"
}

export enum OrderState {
  ABANDONED = "ABANDONED",
  ACCEPTED = "ACCEPTED",
  CANCELLED = "CANCELLED",
  CART = "CART",
  COMPLETED = "COMPLETED",
  EMPLOYEE_CART = "EMPLOYEE_CART",
  ON_HOLD = "ON_HOLD",
  PREPARING = "PREPARING",
  READY = "READY",
  REFUSED = "REFUSED",
}

export enum PaymentMethod {
  BADGE = "BADGE",
  CREDIT_CARD = "CREDIT_CARD",
  EDENRED = "EDENRED",
  ON_SITE = "ON_SITE",
}

export interface QuerySearchInput {
  key: string;
  operator?: string | null;
  value: string | string[];
}

//==============================================================
// END Enums and Input Objects
//==============================================================

export default null
